<template>
  <div class="cooperative-clients">
    <div class="w content ty300" ref="transformRef1">
      <div class="title-img">
        <img src="@/assets/images/cold-chain-image/page17.png" alt="">
      </div>

      <div class="scroll-box" ref="scrollRef">
        <div class="item-box" ref="boxRef">
          <div class="item" v-for="(item, index) in listImg" :key="index">
            <img :src="item" alt="">
          </div>
        </div>
        <div class="item-box" ref="boxRef2">
          <div class="item" v-for="(item, index) in listImg" :key="index">
            <img :src="item" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  reactive, watchEffect, toRefs, onMounted, onUnmounted,
} from 'vue';
import { itemGsap } from '@/views/index/js/usePage';
import apis from '@/request/apis';
import img1 from '@/assets/images/cold-chain-image/page31.png';
import img2 from '@/assets/images/cold-chain-image/page32.png';
import img3 from '@/assets/images/cold-chain-image/page33.png';
import img4 from '@/assets/images/cold-chain-image/page34.png';
import img5 from '@/assets/images/cold-chain-image/page35.png';

export default {
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const data = reactive({
      count: 0,
      transformRef1: null,
      partnersPicList: [],
      boxRef: null,
      scrollRef: null,
    });
    const listImg = [
      img1,
      img2,
      img3,
      img4,
      img5,
    ];

    const detail = async () => {
      const res = await apis.receptionEnterSpikeDepth.getDetail();
      const { partnersPic } = res;
      if (partnersPic) {
        data.partnersPicList = partnersPic.split(',');
      }
    };
    detail();

    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.isView && data.count === 1) {
        itemGsap(data.transformRef1, {
          opacity: 1,
          duration: 1.5,
          y: 0,
        });
      }
    });
    const obj = {
      x: 0,
      moveX: 0,
      oldMoveX: 0,
    };
    let downFlag = false;

    const mousedown = (e) => {
      downFlag = true;
      obj.x = e.x;
    };
    const mouseup = () => {
      downFlag = false;
      obj.oldMoveX = obj.moveX;
    };
    const mouseleave = () => {
      downFlag = false;
      obj.oldMoveX = obj.moveX;
    };
    const mousemove = (e) => {
      if (downFlag) {
        obj.moveX = e.x - obj.x;
        data.boxRef.scrollLeft = obj.oldMoveX - obj.moveX;
        obj.moveX = data.boxRef.scrollLeft;
      }
    };
    let timerId = null;

    const scroll = () => {
      timerId = setInterval(() => {
        data.scrollRef.scrollLeft += 1;
        if (data.scrollRef.scrollLeft >= data.boxRef.offsetWidth) {
          data.scrollRef.scrollLeft = 0;
        }
      }, 10);
    };

    onMounted(() => {
      scroll();
    });

    onUnmounted(() => {
      clearInterval(timerId);
    });

    return {
      ...toRefs(data),
      mousedown,
      mouseup,
      mouseleave,
      mousemove,
      listImg,
    };
  },
};
</script>

<style lang="scss" scoped>
.cooperative-clients {
  width: 100vw;
  height: 25.521vw;
  background-image: url('~~@/assets/images/cold-chain-image/page09.png');
  transition: height 0.5s, opacity 0.5s;
  background-position: 50% 50%;
  background-size: 100% 100%;
  padding-top: 4.844vw;
  .content {
    .title-img {
      width: 31.51vw;
      height: 5.729vw;
      // margin-top: 4.844vw;
      margin-bottom: 2.344vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .scroll-box {
      display: flex;
      overflow-x: scroll;
    }
    .item-box {
      // overflow-x: auto;
      display: flex;
      .item {
        width: 15vw;
        height: 7.813vw;
        margin-right: 0.729vw;
        flex-shrink: 0;
        background-color: #fff;
        img {
          display: flex;
          height: 100%;
          width: 100%;
          object-fit: cover;
          pointer-events: none;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .cooperative-clients {
    width: 100vw;
    height: auto;
    background-image: url('~~@/assets/images/cold-chain-image/page09.png');
    transition: height 0.5s, opacity 0.5s;
    background-position: 50% 50%;
    background-size: auto 100%;
    padding-top: 4.844vw;
    padding-bottom: 4.844vw;
    .content {
      .title-img {
        width: 50vw;
        height: auto;
        // margin-top: 4.844vw;
        margin-bottom: 2.344vw;
        margin-left: 4vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .scroll-box {
        display: flex;
        overflow-x: scroll;
      }
      .item-box {
        // overflow-x: auto;
        display: flex;
        .item {
          width: 35vw;
          height: 17.813vw;
          margin-right: 2.729vw;
          flex-shrink: 0;
          background-color: #fff;
          img {
            display: flex;
            height: 100%;
            width: 100%;
            object-fit: cover;
            pointer-events: none;
          }
        }
      }
    }
  }
}

</style>
