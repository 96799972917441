<template>
  <div class="logistics-delivery ty300" ref="transformRef1">
    <div class="left">
      <div class="title-img">
        <img src="@/assets/images/cold-chain-image/page26.png" alt="">
      </div>
      <div class="title3">
        以技术驱动，构建透明化物流履约交付能力，延展物流数智化增值服务。
      </div>
    </div>
    <div class="w right">
      <div class="box">
        <div class="top">
          <div class="img-box">
            <el-image fit="cover" lazy :src="img1"/>
          </div>
          <div class="img-box">
            <el-image fit="cover" lazy :src="img2"/>
          </div>
        </div>
        <div class="bot">
          <el-image fit="cover" lazy :src="img3"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from '@/assets/images/cold-chain-image/page06.png';
import img2 from '@/assets/images/cold-chain-image/page08.png';
import img3 from '@/assets/images/cold-chain-image/page01.png';
import { reactive, toRefs, watchEffect } from 'vue';
import { itemGsap } from '@/views/index/js/usePage';

export default {
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const data = reactive({
      count: 0,
      transformRef1: null,
    });

    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.isView && data.count === 1) {
        itemGsap(data.transformRef1, {
          opacity: 1,
          duration: 1.5,
          y: 0,
        });
      }
    });
    return {
      img1,
      img2,
      img3,
      ...toRefs(data),
    };
  },
};
</script>

<style scoped lang="scss">
.logistics-delivery {
  width: 100vw;
  height: 42.917vw;
  display: flex;
  position: relative;
  background-color: #fff;
  .left {
    display: flex;
    flex-direction: column;
    width: 40.625vw;
    background-image: url('~~@/assets/images/cold-chain-image/page03.png');
    transition: height 0.5s, opacity 0.5s;
    background-position: 50% 50%;
    background-size: 100% 100%;
    color: #fff;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    .title-img {
      width: 31.51vw;
      height: 5.469vw;
      margin-top: 8.177vw;
      margin-left: 10.938vw;
      margin-bottom: 3.281vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .title3 {
      width: 25.885vw;
      height: 3.75vw;
      font-size: 1.042vw;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 1.875vw;
      margin-left: 10.938vw;
    }
  }
  .right {
    padding-left: 28.646vw;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .box {
      width: 43.75vw;
      .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.354vw;
        .img-box {
          width: 21.458vw;
          height: 12.188vw;
          overflow: hidden;
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .bot {
        width: 100%;
        height: 21.771vw;
        overflow: hidden;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .logistics-delivery {
    width: 100vw;
    height: auto;
    display: flex;
    position: relative;
    background-color: #fff;
    flex-direction: column;
    .left {
      display: flex;
      flex-direction: column;
      width: 100vw;
      height: 30vw;
      background-image: url('~~@/assets/images/cold-chain-image/page03.png');
      transition: height 0.5s, opacity 0.5s;
      background-position: 50% 50%;
      background-size: auto 100%;
      color: #fff;
      position: relative;
      left: 0;
      bottom: 0;
      top: 0;
      .title-img {
        width: 50vw;
        height: auto;
        margin-top: 3vw;
        margin-left: 4vw;
        margin-bottom: 3.281vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .title3 {
        width: 60vw;
        height: auto;
        font-size: 1.867vw;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 4.267vw;
        margin-left: 4vw;
      }
    }
    .right {
      padding-left: 0vw;
      display: none;
      align-items: center;
      justify-content: flex-end;
      .box {
        width: 100vw;
        .top {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1.354vw;
          .img-box {
            width: 50vw;
            height: 22.188vw;
            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .bot {
          width: 100vw;
          height: 21.771vw;
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
</style>
