<template>
  <div class="logistics-services">
    <div class="w ty300" ref="transformRef1">
      <div class="title-img">
        <img src="@/assets/images/cold-chain-image/page46.png" alt="">
      </div>

      <div class="text">
        穗深冷链供应链以“提供智慧冷链全链路解决方案”为核心使命，基于集团产业发展战略，在华南(广州、深圳、佛山、东莞)、华东(上海、南京、杭州、苏州、湖州)、西南 (成都、重庆) 、华中(武汉、长沙)等十余个核心城市布局了冷链园区，优化整合冷链产业上下游，打造以产业生态+产业运营为核心的智慧冷链综合体4.0，全面融合自营冷链物流+综合交易+集约化加工+互联网金融+O2O电商,提供一站式运营管理服务，从而实现了适配不同温度需求、从种子到筷子的全链路仓储配送服务体系。
      </div>
    </div>
  </div>
</template>

<script>
import {
  reactive, watchEffect, toRefs,
} from 'vue';
import { itemGsap } from '@/views/index/js/usePage';

export default {
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const data = reactive({
      count: 0,
      transformRef1: null,
    });

    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.isView && data.count === 1) {
        itemGsap(data.transformRef1, {
          opacity: 1,
          duration: 1.5,
          y: 0,
        });
      }
    });

    return {
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
.logistics-services {
  width: 100vw;
  height: 29.688vw;
  background-image: url('~@/assets/images/cold-chain-image/page04.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% 100%;
  overflow: hidden;

  .title-img {
    width: 31.51vw;
    height: 5.938vw;
    margin-top: 4.427vw;
    margin-bottom: 3.229vw;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .text {
    text-indent: 2.084vw;
    width: 41.875vw;
    font-size: 1.042vw;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 2.083vw;
  }
}
@media screen and (max-width: 768px) {
  .logistics-services {
    width: 100vw;
    height: auto;
    background-image: url('~@/assets/images/cold-chain-image/page04.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 100%;
    overflow: hidden;

    .title-img {
      width: 40vw;
      height: auto;
      margin-top: 4.427vw;
      margin-left: 4vw;
      margin-bottom: 3.229vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .text {
      padding: 1.333vw;
      padding-bottom: 2.667vw;
      text-indent: 3.733vw;
      width: 100%;
      font-size: 1.867vw;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 4.267vw;
    }
  }
}
</style>
