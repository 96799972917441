<template>
  <div class="w core-advantages ty300" ref="transformRef1">
    <div class="title-img">
      <img src="@/assets/images/cold-chain-image/page22.png" alt="">
    </div>

    <div class="item-box">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="img-box">
          <img class="img1" :src="item.img" alt="">
          <img class="img2" :src="item.hoverImg" alt="">
        </div>
        <div class="cont">
          <div class="title">{{ item.title }}</div>
          <div class="bor"></div>
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from '@/assets/images/cold-chain-image/page19.png';
import img2 from '@/assets/images/cold-chain-image/page20.png';
import img3 from '@/assets/images/cold-chain-image/page21.png';
import img4 from '@/assets/images/cold-chain-image/page23.png';
import img5 from '@/assets/images/cold-chain-image/page24.png';
import img6 from '@/assets/images/cold-chain-image/page25.png';
import { itemGsap } from '@/views/index/js/usePage';
import { reactive, toRefs, watchEffect } from 'vue';

export default {
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const list = [
      {
        title: '团队经验丰富',
        text: '团队成员具有十多年冷链物流行业经验，具有丰富的专业知识，能够为客户提供最优质的解决方案和品质服务；拥有完善的运营管理体系和操作流程，能够确保项目的顺利实施和高质量的交付。',
        img: img1,
        hoverImg: img4,
      },
      {
        title: '客制化服务',
        text: '公司深耕冷链物流行业多年，洞察冷链行业各产品、客户的物流需求。行业资源丰富，可针对不同行业、不同类型客户提供全场景、定制化的冷链物流解决方案。',
        img: img5,
        hoverImg: img2,
      },
      {
        title: '科技驱动',
        text: '公司自主研发的冷链物流管理系统，对客户的产品实现7天24小时在库、生产、在途等实时监控，实现订单、温度可视化运营。模块化管理商品的精准动销、临期预警等，优化供应链效率，高效交付、品质必达。',
        img: img3,
        hoverImg: img6,
      },
    ];

    const data = reactive({
      count: 0,
      transformRef1: null,
    });

    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.isView && data.count === 1) {
        itemGsap(data.transformRef1, {
          opacity: 1,
          duration: 1.5,
          y: 0,
        });
      }
    });

    return {
      list,
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
.core-advantages {
  height: 47.292vw;
  display: flex;
  flex-direction: column;
  .title-img {
    width: 31.51vw;
    height: 5.938vw;
    margin-top: 4.896vw;
    margin-bottom: 2.448vw;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .item-box {
    display: flex;
    height: 27.604vw;
    justify-content: space-between;
    .item {
      width: 24.479vw;
      height: 100%;
      background: #fff;
      transition: all 0.3s;

      .img-box {
        width: 100%;
        margin-top: 3.229vw;
        margin-bottom: 1.25vw;
        padding: 0 2.604vw;
        img {
          display: block;
          width: 4.01vw;
          height: 3.75vw;
        }
        .img1 {
          display: block;
        }
        .img2 {
          display: none;
        }
      }
      &:hover {
        .img-box {
          .img1 {
            display: none;
          }
          .img2 {
            display: block;
          }
        }

      }
      .cont {
        padding: 0 2.604vw;
        .title {
          height: 1.719vw;
          font-size: 1.458vw;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: var(--baseColor1);
          line-height: 1.719vw;
          transition: all 0.3s;

        }
        .bor {
          width: 100%;
          height: 1px;
          position: relative;
          background-color: #9D815A;
          margin-top: 0.781vw;
          margin-bottom: 1.146vw;
          transition: all 0.3s;
          &::before {
            content: '';
            position: absolute;
            width: 7.813vw;
            height: 0.313vw;
            top: -0.156vw;
            background-color: #9D815A;
          }
        }
        .text {
          height: 5.938vw;
          font-size: 0.938vw;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 1.875vw;
          text-indent: 1.875vw;
          transition: all 0.3s;
        }
      }
      &:hover .bor,
      &:hover .bor::before {
        background-color: #fff;
      }
      &:hover {
        background-color: var(--baseColor1);
      }
      &:hover .title,
      &:hover .text {
        color: #fff;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .core-advantages {
    height: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 4vw;
    .title-img {
      width: 50vw;
      height: auto;
      margin-top: 4.896vw;
      margin-left: 4vw;
      margin-bottom: 2.448vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .item-box {
      padding: 0 1.333vw;
      display: flex;
      height: 60vw;
      justify-content: space-between;
      overflow-x: scroll;
      .item {
        width: 40.479vw;
        flex-shrink:0;
        height: 100%;
        background: #fff;
        transition: all 0.3s;
        margin-right: 2.667vw;

        .img-box {
          width: 100%;
          margin-top: 3.229vw;
          margin-bottom: 1.25vw;
          padding: 0 2.604vw;
          img {
            display: block;
            width: 6.01vw;
            height: 5.75vw;
            object-fit: cover;
          }
          .img1 {
            display: block;
          }
          .img2 {
            display: none;
          }
        }
        &:hover {
          .img-box {
            .img1 {
              display: none;
            }
            .img2 {
              display: block;
            }
          }

        }
        .cont {
          padding: 0 2.604vw;
          .title {
            height: 1.719vw;
            font-size: 1.458vw;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: var(--baseColor1);
            line-height: 1.719vw;
            transition: all 0.3s;
            margin-top: 2.333vw;
            margin-bottom: 2.333vw;

          }
          .bor {
            width: 100%;
            height: 1px;
            position: relative;
            background-color: #9D815A;
            margin-top: 0.781vw;
            margin-bottom: 1.146vw;
            transition: all 0.3s;
            &::before {
              content: '';
              position: absolute;
              width: 7.813vw;
              height: 0.313vw;
              top: -0.156vw;
              background-color: #9D815A;
            }
          }
          .text {
            height: auto;
            font-size: 1.867vw;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 4.267vw;
            text-indent: 3.733vw;
            transition: all 0.3s;
          }
        }
        &:hover .bor,
        &:hover .bor::before {
          background-color: #fff;
        }
        &:hover {
          background-color: var(--baseColor1);
        }
        &:hover .title,
        &:hover .text {
          color: #fff;
        }
      }
    }
  }
}
</style>
